.nav-link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 24px;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}

h1.page-title {
  font-family: 'Sitka';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;

  color: rgba(229, 230, 235, 1) !important;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  text-align: center;
}

.content-block {
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(61, 56, 51, 0.8) !important;
  padding: 20px;
  border-radius: 20px !important;
}

.content-block-full {
  height: 80vh !important;
}

.regular-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: rgba(229, 230, 235, 1) !important;
}

.shadowed-text {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.block-shadow {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
}

.center-text {
  text-align: center;
}

.item-card {
  background-color: rgba(0, 0, 0, 0.0) !important;
  border: none !important;
  margin-left: 10px;
  margin-right: 10px;
}

.item-card:hover .item-card-overlay {
  background-color: rgba(0, 0, 0, 0.0) !important;
  border: none !important;
  ;
  margin-left: 20px;
  margin-right: 20px;
}

.item-card-overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.course-description-block {
  padding-left: 20px;
}

.to-cart-button {
  text-transform: uppercase;
  font-family: 'Sitka';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
}

.main-page-title{
  font-family: 'Sitka';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  text-align: left;
  color: white;
}